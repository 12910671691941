@import '../../../styles/vars';

.chatboxWrapper {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  position: relative;
}

.chatbox {
  resize: none;
  width: 100%;
  padding-right: 120px;
}

.toolbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.root {

}

.notesWrapper {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.note {
  width: 90%;
  height: auto;
  margin: 0;
  padding: 1rem 1rem;
  border-radius: 5px;
  border-top-left-radius: 0;
  background-color: rgba(0, 0, 0, 0.1);

  p {
    margin: 0;
    padding: 0;
  }

  a {
    font-size: 12px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.noteAuthor {
  background-color: $primary-color;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 0;
  margin-left: auto;

  a {
    color: white !important;
  }
}
