@import 'src/styles/vars';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 100vh;
  background-color: $background-color;
}

.aside {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  background-color: $aside-color;
  width: 240px;
  flex-shrink: 0;
  box-shadow: 0 1px 30px 1px rgba(0,0,0,.11);
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  padding-top: 60px;
  transition: $transition;
}

.asideMini {
  width: 70px;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  width: calc(100% - 240px);
  margin-top: 60px;
  margin-left: 240px;
  transition: $transition;
}

.mainMini {
  margin-left: 70px;
  width: calc(100% - 70px);
}

.mainHeader {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,.05);
  z-index: 101;
  background-color: white;
  align-items: center;
}

.toggleMenuBtn {
  border: none;
  height: 60px;
  width: 70px;
  padding: 0;
  margin: 0;
  background-color: white;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: $transition;

  i {
    font-size: 1.5rem;
  }

  &:hover, &:active {
    background-color: tint($primary-color, 95%);
    color: $primary-color;
  }
}

.topBarActions {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topBarProfile {
  border: none;
  background: none;
  width: auto;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 35px;
    width: 35px;
    border-radius: 100%;
  }

  span {
    color: $primary-color;
    font-weight: 500;
    display: block;
    font-size: 1.25em;
  }
}

.asideNav {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;

    li {
      width: 100%;
      height: auto;

      a {
        width: 100%;
        min-height: 45px;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: shade($primary-color, 75%);
        transition: $transition;

        &:hover, &:active {
          background-color: tint($primary-color, 95%);
        }

        i {
          display: block;
          width: 30px;
          text-align: left;
        }

        span {
          font-weight: 400;
        }
      }
    }
  }
}

.languagePicker {
  img {
    height: 25px;
    width: auto;
    opacity: 0.5;
    filter: grayscale(100%);
    transition: $transition;
    cursor: pointer;

    &:hover, &:active {
      opacity: 1;
    }
  }
}

.languageSelected {
  opacity: 1 !important;
  filter: none !important;
}
