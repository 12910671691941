.rootWrapper {
  width: 100%;
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.1);
}

.root {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 5px;
  color: rgb(28, 30, 33);
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.profilePicture {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
}

.content {
  width: 100%;
  height: auto;
}
