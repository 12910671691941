@import 'src/styles/vars';

.root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 150;
}
