@import 'src/styles/vars';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 100vh;
  background-color: $background-color;
  align-items: center;
  justify-content: center;
}
