.userCard {
  :global .p-card-body, :global .p-card-content {
    padding: 0;
  }

  :global .p-datatable-wrapper {
    border-radius: 5px;
    overflow: hidden;
  }
}

.profilePicture {
  border-radius: 100%;
  width: 75px;
  height: 75px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
