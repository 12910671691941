@import './vars';
@import './theme';
@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';
@import '~primeflex/primeflex.css';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,100&display=swap');

html {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  padding: 0;
  margin:0 ;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 1em;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: 100vh;
}

@for $i from 0 through 20 {
  .p-opacity-#{$i * 5} {
    opacity: ($i * 5) / 100 !important;
  }
}

@for $i from 5 through 20 {
  .p-fs-#{$i - 4} {
    font-size: ($i * 0.125) * 1em !important;
  }
}

.p-invalid {
  color: $error-color !important;
}

.w-100 {
  width: 100% !important;
}

.p-button-icon.p-button-lg {
  width: 2.357rem + 0.25rem;
  height: 2.357rem + 0.25rem;
  padding: 1.5rem + 0.25rem;

  .pi {
    font-size: 1.25rem;
  }
}

.sidebar-nav {
  a.active {
    color: $primary-color !important;
  }
}

button.style-less {
  border: none;
  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

h1.page-title {
  color: $primary-gray-color;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 1.75em;
  margin: 0;
  padding: 0;
}

.p-text-primary {
  color: $primary-color !important;
}

.p-text-primary-gray {
  color: $primary-gray-color !important;
}

.p-text-error {
  color: $error-color !important;
}

.p-column-filter {
  width: 100%;
}

.p-chip.primary {
  background-color: $primary-color;
  color: white;
}

.p-chip.warn {
  background-color: $warn-color;
  color: rgba(0, 0, 0, 0.75);
}

.p-chip.error {
  background-color: $error-color;
  color: white;
}

.p-table {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  tr {
    td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    &:last-of-type {
      td {
        border-bottom: none;
      }
    }
  }

  tr:nth-child(2n) {
    td {
    }
  }

  td {
    padding: 1rem;
    border-right: 1px solid rgba(0, 0, 0, 0.15);

    &:last-of-type {
      border-right: none;
    }

    &.p-table-header {
      font-weight: bold;
    }
  }
}

.fc-toolbar-chunk {
  display: flex;
  flex-direction: row;
  align-items: center;

  .fc-button-group {
    height: 34.2px;
  }
}

.p-card.content-center {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .p-card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .p-card-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}

.p-datatable .p-datatable-tbody > tr > td {
  word-break: break-all;
  //overflow: hidden;
}

.p-table-card {
  .p-card-body, .p-card-content {
    padding: 0;
  }

  .p-datatable-wrapper {
    border-radius: 5px;
    //overflow: hidden;
  }
}

.p-inputnumber.w-100 input {
  width: 100%;
}

.text-color-facebook {
  color: $facebook-color !important;
}

.text-color-instagram {
  color: $instagram-color !important;
}
