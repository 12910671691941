.sentimentCard {
  i {
    font-size: 3rem;
  }

  span {
    font-size: 1.25rem;
  }

  :global .p-card-body {
    text-align: center;
  }
}

.kpiCard {
  i {
    font-size: 2rem;
  }

  span {
    font-size: 1.5rem;
  }

  :global .p-card-body {
    text-align: center;
  }
}

.noPaddingCard {
  :global .p-card-body, :global .p-card-content {
    padding: 0;
  }

  :global .p-datatable-wrapper {
    border-radius: 5px;
    overflow: hidden;
  }
}
