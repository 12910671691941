$background-color: #f2f7f6;
$primary-color: #54b19d;
$warn-color: #e3d360;
$error-color: #d96161;
$aside-color: #ffffff;
$primary-gray-color: #3e4342;
$transition: all 0.25s ease-in-out;
$facebook-color: #4267B2;
$instagram-color: #833ab4;

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}
