@import 'src/styles/vars';
@import '~primeicons/primeicons.css';

.personCard {
  position: relative;
  opacity: 0.90;
  cursor: pointer;
  transition: $transition;

  &:hover {
    opacity: 1;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  h3 {
    font-size: 1.25em;
    font-weight: 500;
    color: $primary-color;
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  small {
    color: $primary-gray-color;
    opacity: 0.75;
  }

  &__socialBar {
    width: 100%;
    padding: 0;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: yellow;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &__facebook {
      background-color: $facebook-color;
      color: white;
      font-weight: 500;
    }

    &__instagram {
      background-color: $instagram-color;
      color: white;
      font-weight: 500;
    }
  }

  :global .p-card-body {
    padding-top: calc(1rem + 30px);
  }
}
